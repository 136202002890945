import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, License } from '@components';

const { Banner } = Banners;
export default function Index() {
  return (
    <Layout
      bannerChildren={
        <Banner title='License and Attributions' description='Updated on 2022-04-30' />
      }
    >
      <SEO
        title='TAIK - LICENSE AND ATTRIBUTIONS'
        description="The Africa I Know, Inc' License and Attributions."
      />
      <License />
    </Layout>
  );
}
